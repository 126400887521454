.container {  
  display: grid;
  grid-template-columns: 5fr 10fr 5fr;
  grid-template-rows: 1.2fr;
  gap: 0px 0px;
  background-color: white;
}

.middle { grid-area: 1 / 2 / 2 / 3; background-color: white;}

.left { grid-area: 1 / 1 / 2 / 4; background-color: whitesmoke;}

.right { grid-area: 1 / 3 / 2 / 4; background-color: whitesmoke;}
